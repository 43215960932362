<template>
<div class="modal">
  <div class="navbar">
    <img class="icon" src="@/assets/icon.svg" alt="Answie Icon" />
    
  </div>
  <div class="answer-frame">
    
    <div class="modal-card" style="padding-bottom:10%">
      <a v-on:click="closeModal" class="button-close"
      ><span class="material-icons">close</span></a>
      <div class="search-for">Search for:</div>
      <h2 class="question">{{ query }}</h2>
      <div class="title">
        <span class="material-icons language-icon">language</span>{{ url }}
      </div>
      <div v-if="answerLoading" class="card-content">
        <img src="@/assets/loading.gif" style="height: 15px;" /> Answer is
        being loaded...
      </div>
      <div v-else-if="answer" class="card-content suggestion">
        <a :href="getHighlightURL(answer)" target="_blank"> {{answer}} </a>
        <hr/>
        <div>
          <i>Result from <a target="_blank" :href="'https://en.wikipedia.org/wiki/Large_language_model'">LLMs</a> (like ChatGPT)</i><br>
          {{llm_answer}} 
        </div>
      </div>
      <div v-else class="card-content">
        No answer was found in this web page.
      </div>

      <div v-if="suggestionsLoading">
        <img src="@/assets/loading.gif" style="height: 15px;" /> Suggestions
        are being loaded...
      </div>


      <div v-else-if="suggestions">
        <div class="title">More Suggestions</div>
        <div
          class="suggestion"
          v-for="suggestion in suggestions"
          :key="suggestion"
        >
          <a :href="getHighlightURL(suggestion)" target="_blank"> {{suggestion}} </a>
        </div>
      </div>
      <div v-else>No suggestions are available for this web page.</div>


      <div v-if="loading" class="error">
        <img src="@/assets/loading.gif" />
      </div>

      <div class="search-for see-more">
        See more at <a :href="url" target="_blank">{{ url }}</a>
      </div>

      <transition name="bounce">
        <a
            style="cursor: pointer;margin-left:10px;color: thumbDownState!=0 ? '#dced31' : '#0D1821'"
            class="button-white"
            v-if="thumbDownState < 2"
          v-on:click="feedback(-1)"
            >
        <span class="material-icons">thumb_down_alt</span>
        </a>
        </transition>
      <transition name="bounce">
        <a
          style="cursor: pointer;color: thumbUpState!=0 ? '#dced31' : '#0D1821'"
          class="button-white"
        v-if="thumbUpState < 2"
        v-on:click="feedback(1)"
          >
        <span class="material-icons">thumb_up_alt</span>
        </a>
      </transition>

    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
// import Card from '@/components/Card.vue'

export default {
  name: 'Details',
  components: {
  },
  data() {
    return {
      query: '',
      url: '',
      thumbUpState: 0,
      thumbDownState: 0,
      answer: null,
      llm_answer: null,
      answerLoading: true,
      suggestions: null,
      suggestionsLoading: true,
      loading: false,
      // error: null,
      // results: null,
    }
  },
  created() {
    // fetch the data whe[n the view is created and the data is
    // already being observed
    this.getAnswers()
  },
  /* watch: {
    // call again the method if the route changes
    '$route': 'getAnswers'
  }, */
  beforeRouteUpdate() {
    this.getAnswers
  },
  methods: {
    getHighlightURL(suggestion) {
      const urlObj = new URL(this.url);
      const words = suggestion.split(" ");

      if (words.length >= 8)
        urlObj.hash = '#:~:text=' + encodeURIComponent(words[0] + " " + words[1] + " " + words[2] + " " + words[3]) + "," + encodeURIComponent(words[words.length - 4] + " " + words[words.length - 3] + " " + words[words.length - 2] + " " + words[words.length - 1]);
      else
        urlObj.hash = '#:~:text=' + encodeURIComponent(suggestion);
      
      const link = urlObj.href;
      return link;
    },

    getAnswers () {
      this.error = this.results = null;
      this.loading = true;

      this.query = this.$route.params.query;
      this.url = this.$route.params.url;

      //const url = process.env.VUE_APP_BACKEND_URL + "search/v2/answer-alternatives/" + encodeURIComponent(this.query);
      const url = process.env.VUE_APP_BACKEND_URL + "as2";
      
      const body = { url: this.url, question: this.query, source: 'isa-web-main', user_id: '' };
      fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((response) => {
          //this.answer = response["answer"];
          //this.suggestions = response["alternatives"];
          // this.passage = response["passage"];
          // this.passage_index = response["passage_index"];
          
          const sentencesWithScores = response;
          const selectedSentences = sentencesWithScores.map(function(e){return e[0]});
          this.llm_answer = selectedSentences[0];
          this.answer = selectedSentences[1];
          this.suggestions = selectedSentences.slice(1);

          this.loading = false;
          this.suggestionsLoading = false;
          this.answerLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeModal() {
      this.$router.push({ name: 'Search', params: { query: this.query }  })
    },
    feedback(vote) {
      this.thumbUpState = 2;
      this.thumbDownState = 2;
      
      this.query = this.$route.params.query;
      this.url = this.$route.params.url;

      const answieURL = 'https://answie.com/search/' + encodeURIComponent(this.query) + '/details/'+ encodeURIComponent(this.url)  
      console.log(vote + ' ' + answieURL);

      const url = process.env.VUE_APP_BACKEND_URL + "search/v2/feedback"

      let params = {url:answieURL, vote: vote,query:this.query};
      console.log(params)

      fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.isErrorOccured = true;
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          console.log(response['result']);
        })
        .catch((err) => {
          console.log(err);
        });
      },    
  }

}
</script>

<style scoped>

.suggestion a{
  color: #0D1821 !important;
}

.suggestion:hover{
  cursor: pointer;
  /* background: #DCED31; */
  background-color: #EEF698;
}

</style>
