<template>
  <div>

    <SearchBar curQuery="What is 404 Not Found" />

    <div style="text-align: center">
      <img src="@/assets/error-icon.svg" alt="Error icon" />
      <h3>404 Not Found</h3>
        <p>Sorry, the page you are looking for could not be found.</p>
    </div>
    <!-- <h1 v-if="loading">LOADING</h1>
    <h1 v-for="result in results" :key="result.id" v-on:click="seeMore(result.id)" >{{result.id}} : {{result.snippet}}</h1> -->
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
// import Card from '@/components/Card.vue'
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'NotFound',
  components: {
    SearchBar,
  },
  data() {
    return {
    }
  },
}
</script>
