<template>
<div class="top-frame">
  <div class="navbar">
    <img class="icon" src="@/assets/icon.svg" alt="Answie Icon" />

    <a
      class="button-black"
      href="https://chrome.google.com/webstore/detail/answie/dlfabbooencindafhkckoigghebcpaep"
      ><span class="text">Add to Chrome</span></a
    >

    <a
      class="button-white"
      href="https://www.paypal.com/donate?hosted_button_id=XULC78B4T7EYY"
      ><span class="text">Buy us a coffee</span
      ><span class="material-icons">local_cafe</span></a
    >
  </div>

  <div class="container" style="text-align: center; align-items: center">
    <img
      src="@/assets/person.svg"
      v-bind:style="[
        curQuery ? 'width:100px;height:100px;float:left;' : '',
      ]"
      alt="Answie Search"
    />

    <div class="form">
      <input
        placeholder="Enter your question"
        ref="question"
        v-model="query"
        v-on:keyup.enter="search"
      />
      <button v-on:click="search">
        <span class="material-icons">search</span>
      </button>
    </div>

    <h1 v-if="!curQuery">
      Save time for searching answers on web
    </h1>
    <p v-if="!curQuery">
      We will search the web pages and lists possible answers. We read for
      you so you don't have to for free!
    </p>
  </div>
</div>


<!-- 
  <div>
    <input v-model="query" v-on:keyup.enter="search" type="text" class="form-control" id="exampleInputEmail1">
    <button v-on:click="search" class="btn btn-primary">Submit</button>
  </div> -->
</template>


<script>
export default {
  name: 'SearchBar',
  props: {
    curQuery: String
  },
  components: {
  },
  data() {
    return {
      query: "",
    }
  },
  watch: {
    curQuery(newVal) {
      this.query = newVal;
    }
  },
  created() {
    if(this.curQuery != null) {
      this.query = this.curQuery;
    }
  },
  methods: {
    search() {
      this.$router.push({name: 'Search', params: { query: this.query }  })
    },
  }
}

</script>


<style>

</style>
