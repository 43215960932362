<template>
  <div>

    <div v-if="loading" class="loading">
      <div class="uil-ring-css" style="transform:scale(0.79);">
        <div></div>
      </div>
    </div>


    <SearchBar :curQuery="this.query" />
    <div v-if="!error" class="answer-frame">
      <h2>Result from <a target="_blank" :href="'https://en.wikipedia.org/wiki/Large_language_model'">LLMs</a> (like ChatGPT)</h2>
      <ul>
          <li v-for="content in answers" :key="content" >
              {{ content }}
          </li>
      </ul>
      <h2>Answers</h2>
      <Card
          v-for="content in results"
          :key="content.id"
          :query="query"
          :link="content.url"
          :date="content.date"
          :title="content.title"
          :content="content.snippet"
        />
    </div>
    <div v-if="error" style="text-align: center">
      <img src="@/assets/error-icon.svg" alt="Error icon" />
      <h3>Oops...</h3>
      <p>Sorry, something went wrong, please<br />retry your search</p>
    </div>
    <!-- <h1 v-if="loading">LOADING</h1>
    <h1 v-for="result in results" :key="result.id" v-on:click="seeMore(result.id)" >{{result.id}} : {{result.snippet}}</h1> -->
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
// import Card from '@/components/Card.vue'
import SearchBar from '@/components/SearchBar.vue'
import Card from '@/components/Card.vue'

export default {
  name: 'Home',
  components: {
    SearchBar,
    Card
  },
  data() {
    return {
      query: '',
      loading: false,
      error: null,
      results: null,
      answers: null,
    }
  },
  created() {
    // fetch the data whe[n the view is created and the data is
    // already being observed
    this.query = this.$route.params.query;
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    // '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.error = null;
      this.loading = true;
      // this.results = null;
      // this.results = [{"snippet": "","title": "","url": ""},];
      // alert(this.results);
      // this.results = [{"snippet": "","title": "","url": ""},{"snippet": "","title": "","url": ""},{"snippet": "","title": "","url": ""},{"snippet": "","title": "","url": ""}]
      const url = process.env.VUE_APP_BACKEND_URL + "search/v2/search/" + encodeURIComponent(this.query);

      // this.answersLoaded = false;
      // this.alternativesLoaded = false;

      fetch(url, {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.error = true;
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.results = response["search_results"];
          this.answers = response["answers"];
          this.error = false;
          this.loading = false;
          console.log(response["search_results"]);
          console.log(response["answers"]);

          // For async fetching
          // this.fetch_answers();
          // this.fetch_alternatives();
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.loading = false;
        });




    },
    seeMore(id) {
      this.$router.push({
        name: 'Details',
        params: {
          query: this.query,
          url: this.results[id].url,
        }
      });
    }
  },
  beforeRouteEnter(to) {
    document.title = to.params.query + " - Answie";
  },
  beforeRouteUpdate(to, from) {
    // console.log("beforeRouteUpdate");
    // console.log(to.params.query);
    // console.log(from.params.query);
    if(to.params.query != from.params.query) {
      // this.results = null;
      this.query = to.params.query;
      document.title = to.params.query + " - Answie";
      this.fetchData();
    }
  },

  beforeRouteLeave () {
    document.title = "Answie";
  }




}
</script>
