<template>
<div class="answer-card">
  <div class="top-link">
    <a :href="link" target="_blank">{{ link }}</a>     
  </div>
  <div class="title">
    <span class="material-icons language-icon">language</span>{{ title }}
  </div>
  <div class="card-content">{{ content }}</div>
  <a class="button-white button-see-more" v-on:click="seeMore"
    ><span class="text">See more</span
    ><span class="material-icons">arrow_forward</span></a
  >
</div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    query: String,
    link: String,
    date: String,
    title: String,
    content: String,
  },
  methods: {
    seeMore() {
      this.$router.push({
        name: 'Details',
        params: {
          query: this.query,
          url: this.link,
        }
      });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
mark {
  background-color: #dced31;
}

.answer-card {
  padding: 16px 24px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #cfd1d3;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 24px 0px;
}

.answer-card:hover {
  box-shadow: 0px 0px 20px gray;
  transition: box-shadow 0.2s ease-in-out;
}

.top-link a {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.5px;
  color: #3d464d;
  margin: 4px 0px;
}

.title {
  margin-top: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
}

.language-icon {
  position: relative;
  font-size: 16px;
  top: 2px;
  margin-right: 4px;
}

.card-content {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  margin: 16px 0px;
}

.modal {
  /* visibility: hidden; */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 300;
  background-color: #e5e5e5;

  overflow-y: auto;
}

.modal-card {
  padding: 24px;
  width: 100%;

  background: #ffffff;
  border: 1px solid #cfd1d3;
  box-sizing: border-box;
  border-radius: 16px;
}

.button-close {
  cursor: pointer;
  float: right;
  margin-left: 24px;
  background: #0d1821;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid #0d1821;
  box-sizing: border-box;
  border-radius: 40px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  width: 56px;
  height: 56px;
  padding: 14px;
}

.search-for {
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #868c90;
}

.question {
  margin: 10px 0;
  font-weight: 600;
  font-size: 39px;
  line-height: 46px;
  letter-spacing: -1px;
  color: #0d1821;
}

.error {
  text-align: center;
  padding: 20px;
}

.suggestion {
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.2px;
  margin: 16px 0;
}

.see-more {
  margin-top: 1em;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

</style>
