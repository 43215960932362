<template>
  <div>
    <SearchBar curQuery="" />
    <div class="middle-frame">
      <div
        style="display: flex; flex-direction:column; align-items:center; text-align:center; padding-bottom:15px"
      >
        <a href="https://chrome.google.com/webstore/detail/answie/dlfabbooencindafhkckoigghebcpaep">
          <img
            src="@/assets/chrome-extension.svg"
            style="margin-top:20px"
            class="extension"
            alt="Chrome Extension"
          />
        </a>
        <h1>Intelligent search that's 20X faster than skimming.</h1>
        <p>
          Comprehends long documents in seconds, understands your questions, and
          highlights only what matters to you.
        </p>
      </div>

      <div class="container-row">
        <div>
          <div class="elipse">
            <img
              src="@/assets/laptop_chromebook.svg"
              style="margin: auto;"
              alt="Laptop Chromebook"
            />
          </div>

          <h2>
            Any website
          </h2>

          <p style="max-width: 300px;">
            Chrome extension that works on any website or results page.
          </p>
        </div>

        <div>
          <div class="elipse">
            <img
              src="@/assets/contact_support.svg"
              style="margin: auto;"
              alt="Contact Support"
            />
          </div>

          <h2>
            Ask questions
          </h2>

          <p style="max-width: 300px;">
            Open extension in any website and type your question. We will search
            for answers
          </p>
        </div>

        <div>
          <div class="elipse">
            <img
              src="@/assets/list_all.svg"
              style="margin: auto;"
              alt="List All"
            />
          </div>

          <h2>
            Get answers and suggestions
          </h2>

          <p style="max-width: 300px;">
            Comprehends long documents in seconds,understands your questions,
            and highlight only what matters to you.
          </p>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'Home',
  components: {
    SearchBar,
  }
}
</script>
